//index.scss
// Fonts
@import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");
@import "react-datepicker/dist/react-datepicker.css";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

/* Font Weights:
   300 - Light
   400 - Regular
   600 - Semi-Bold
   700 - Bold
   800 - Extra-Bold (for headlines)
*/

/* Use the font in your CSS */

h1, h2, h3, h4, h5, h6 {
  font-weight: 800; /* Headlines set to Open Sans Extra Bold */
}

p {
  font-weight: 400; /* Default body copy */
}

p.light {
  font-weight: 300; /* Body copy with less than 12pt font size */
}

:root {
  --primary-color: #00405c;
  --secondary-color: #00a2e8;
  --tint-color: #1f628e;
  --tint-secondary-color: #f2f2f2;
  --danger-color: #ff1616;
  --font-stack: Montserrat, "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  --base-font-size: 16px;
  --navbar-height: 56px;
  --highlight-color: #a68c19;
}

// Simple reset for margin and padding
* {
  margin: 0;
  padding: 0;
}

html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: var(--font-stack);
}

.no-padding-row > * {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

/* Custom CSS to remove rounded corners */
.no-rounded {
  border-radius: 0 !important;
}

// Custom styles to remove rounded corners from all cards
.card {
  border-radius: 0 !important;
}

.card-header,
.card-body,
.card-footer {
  border-radius: 0 !important;
}

html {
  font-size: var(--base-font-size);
}

body {
  font-family: Montserrat, "Open Sans", sans-serif;
}

a {
  color: var(--tint-secondary-color);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.navbar-brand {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

// Utilities
.pointer {
  cursor: pointer;
}

// Font sizes
.fs-6 {
  font-size: 0.85rem !important;
}

.fs-7 {
  font-size: 0.8rem;
}

.fs-8 {
  font-size: 0.65rem;
}

// Colours
.bw-text-primary {
  color: var(--primary-color) !important;
}

.bw-text-secondary {
  color: var(--secondary-color) !important;
}

.bw-bg-primary {
  background-color: var(--primary-color) !important;
  color: white !important;
}

.bw-bg-secondary {
  background-color: var(--secondary-color) !important;
  color: white !important;
}

.bw-bg-tint {
  background-color: #d1c4e9;
  color: #503285;
}

.no-border-card {
  --bs-card-border-color: transparent !important;
  border: none !important;
}

.bw-text-tint {
  color: #503285;
}

.bw-text-danger {
  color: var(--danger-color);
}

.scrollable-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1; /* This will make sure it takes up all available space */
  overflow-y: auto; /* Keeps the content scrollable */
}

.bw-icon-button {
  padding: 1em;
  border: none;
  border-radius: 4px;
  color: var(--tint-color);
  background-color: #d1c4e9;
  transition: 0.3s;

  &.active {
    background-color: var(--tint-color);
    color: #d1c4e9;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  }

  &:not(:disabled):hover {
    background-color: var(--tint-color);
    color: #d1c4e9;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  }
}

.bw-button-primary {
  background-color: var(--primary-color);
  color: #e6e6e6;
  transition: background-color 0.3s, color 0.3s; // Transition for smooth effect

  &:not(:disabled):hover {
    background-color: var(--secondary-color);
    color: #2d3e50;
  }

  &--alt {
    background-color: var(--primary-color);
    color: #e6e6e6;
    transition: background-color 0.3s, color 0.3s;

    &.button-rounded {
      border-radius: 30px !important;
    }
  }
}

.bw-button-secondary {
  background-color: transparent;
  border: 1px solid var(--primary-color) !important;
  color: var(--primary-color);
  transition: background-color 0.3s, color 0.3s;

  &:not(:disabled):hover {
    background-color: var(--primary-color);
    color: #e6e6e6;
  }

  &.button-rounded {
    border-radius: 30px !important;
  }
}

.bw-button-tint {
  background-color: #d1c4e9;
  color: #503285;
  transition: background-color 0.3s, color 0.3s; // Transition for smooth effect

  &:not(:disabled):hover {
    background-color: #503285;
    color: #d1c4e9;
  }
}

.bw-button {
  min-width: 80px;
  text-align: center;
  height: 38px;
  border: none;
  padding: 4px 12px;
  border-radius: 4px;

  &:disabled {
    background-color: #cccccc;
    color: #666666;
  }
}

.bw-button-link {
  transition: all .2s ease;

  &:hover {
    opacity: 0.7;
  }
}

.bw-table {
  td {
    vertical-align: middle; // This aligns the content of the cell vertically in the middle.
  }
}

.login-error {
  color: var(--danger-color);
  height: 24px;
  line-height: 24px;
}

// Add this to your form group or the closest parent element of the ul
.form-group {
  position: relative;
}

ul.auto-complete {
  position: absolute;
  top: 100%; // Positions the ul right below the input
  left: 0;
  width: 100%; // Ensures the ul is as wide as the input
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  padding: 0;
  margin: 0;
  z-index: 1000; // Ensures the ul is above other content
  max-height: 300px; // Optional: limits the height of the dropdown
  overflow-y: auto; // Optional: adds scroll to the dropdown if it's too long
  border-radius: 0 0 0.25rem 0.25rem; // Optional: for rounded corners at the bottom

  li {
    padding: 16px;
    list-style-type: none;

    &:hover {
      background-color: gainsboro;
    }
  }
}

.custom-datepicker {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 4px 0;
  text-align: center;
}

.tiny-heading {
  font-weight: bolder;
  margin-bottom: 4px;
  font-size: 0.8rem;
  color: var(--primary-color);
}

.clipboard-alert {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 300px;
  z-index: 9999; /* Ensure it's above other content */
}

.placeholder-content {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center vertically in the flex container */
  align-items: center; /* Center horizontally */
  flex-grow: 1; /* Take up all available space */
  text-align: center; /* Ensure text is centered */
  // background-size: cover; /* Cover the entire area */
  color: #777; /* Grey text */
  height: 100%;
}

.info-description {
  height: 0.5rem;
  color: var(--primary-color);
  font-size: 0.65rem;
  padding-top: 0.25rem;
  font-weight: bold;
}

.tab-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  padding: 1em;
}

.chart-container {
  height: 100%;
  width: 100%;
  position: relative;
}

.chart-container canvas {
  height: 100% !important;
}

.combo-chart-container {
  min-height: 300px;
  height: 100%;
  width: 100%;
  position: relative;
}

.chart-stat-50 {
  flex: 0 0 100%;
}

.dashboard-select {
  min-width: 140px;

  .css-1dimb5e-singleValue, 
  .css-1jqq78o-placeholder {
    color: white;
  }

  .css-1xc3v61-indicatorContainer, .css-15lsz6c-indicatorContainer {
    padding: 5px;
    color: white;
    // width: 4px;
    &:hover {
      color: white;
    }
  }

  .css-hlgwow {
    padding: 0px 8px;
  }

  & > .css-13cymwt-control, .css-t3ipsp-control {
    cursor: pointer;
    background-color: transparent;
    border-color: white;
    min-height: 32px;
    outline: none;
    box-shadow: none;
    border-width: 1px;
    &:hover {
      border-color: white;
      // background-color: white;
      color: black;
    }
  }
}

.dashboard-select.open {
  .css-1dimb5e-singleValue, 
  .css-1jqq78o-placeholder {
    color: black;
  }
  .css-1xc3v61-indicatorContainer, 
  .css-15lsz6c-indicatorContainer {
    color: gray;
  }

  & > .css-13cymwt-control, .css-t3ipsp-control {
    background-color: white;
  }
}

.dashboard-select.active {
  .css-1dimb5e-singleValue, 
  .css-1jqq78o-placeholder {
    color: black;
  }

  .css-1xc3v61-indicatorContainer, 
  .css-15lsz6c-indicatorContainer {
    color: gray;
  }

  & > .css-13cymwt-control, .css-t3ipsp-control {
    background-color: white;
  }
}

@media screen and (min-width: 560px) {
  .chart-stat-50 {
    flex: 0 0 calc(50% - 0.25rem);
  } 
}

.nav-link.active {
  text-decoration: underline;
}

.export-to-excel {
}

.terms-container {
  height: 40px;
  text-align: end;
  line-height: 40px;
}

.admin-table {
  > tr {
    display: flex;

    > td {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }

    > .table-col-1 {
      flex: 1 1 0 !important;
    }

    > .table-col-2 {
      flex: 2 1 0 !important;
    }
  }
}

.react-select__control {
  background-color: white !important;
}

.react-select__single-value {
  color: black !important;
}

.react-select__input {
  color: black !important;
  background: transparent !important; /* Make sure background is transparent */
}

.react-select__placeholder {
  color: #6c757d !important;
}

.react-select__menu {
  background-color: white !important;
}

.react-select__option {
  color: black !important;
  background-color: white !important;

  &--is-selected {
    color: white !important;
    background-color: #007bff !important;
  }

  &--is-focused {
    color: black !important;
    background-color: #d9d9d9 !important;
  }

  &:hover {
    color: black !important;
    background-color: #d9d9d9 !important;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background-color: white !important;
  box-shadow: 0 0 0px 1000px white inset !important;
}

/* For Firefox */
input:-moz-autofill {
  background-color: white !important;
}

/* For Edge */
input:-ms-autofill {
  background-color: white !important;
}

// Custom styles for the heatmap tables

// Remove bold styling from the table value cells
.shaded-table td {
  font-weight: normal !important; // Ensure no bold in value cells
}

// Dark blue header with white text for the table titles
.shaded-table-header {
  background-color: #00405c; /* Dark blue background */
  color: #ffffff; /* White text */
  font-weight: normal;
  text-align: center;
  padding: 8px;
  border: 1px solid #00405c;
}

// Reverting the column headers (Sun, Mon, Tue, etc.) to black text on white background
.shaded-table th {
  background-color: #ffffff !important;
  color: #000000 !important;
  border: 1px solid #00405c; // Dark blue border
  text-align: center;
  font-weight: normal;
}

// Ensuring equal width for day columns (Sun, Mon, Tue, etc.)
.shaded-table .day-column {
  width: 12.5%; /* Adjust this value as needed for equal width */
  text-align: center;
  font-weight: normal;
}

.shaded-table th.day-column {
  width: 12.5%;
  text-align: center;
}

.shaded-table .table-cell-total {
  color: #000000; // Ensure total cells always have dark text
  background-color: #ffffff;
}

// Table borders and layout adjustments
.shaded-table td, .shaded-table th {
  border: 1px solid #00405c; // Dark blue borders for a clean separation
  padding: 8px; // Adjust padding for better spacing
}

// Ensure table uses maximum available width
.shaded-table {
  width: 100%;
  margin: 0;
  padding: 0;
}

// Styling for the shaded table cells
.table-cell {
  padding: 8px;
  border: 1px solid #ddd;
  text-align: center;
  font-size: small;
}

.table-cell-shaded {
  &.light-text {
    color: white;
  }

  &.dark-text {
    color: black;
  }
}

// Specific styling for the total row and time column
.table-header,
.table-cell-time {
  font-weight: normal; // Set to normal for consistency
  text-align: center;
  background-color: #ffffff;
  color: #000000;
  padding: 8px;
  border: 1px solid #00405c;
}

// Modal Header
.terms-modal-title {
  font-size: 24px;
  margin-top: 1em;
  margin-bottom: 0.5em;
}

// Modal Body
.terms-modal-body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  font-size: 14px;
  color: #333;

  h2 {
    font-size: 20px;
    margin-top: 1em;
    margin-bottom: 0.5em;
  }

  p {
    margin-bottom: 1em;
  }

  ul {
    margin-left: 2em;
    list-style-type: disc;

    li {
      margin-bottom: 0.5em;
    }
  }

  // Nested UL for sub-lists
  ul ul {
    margin-left: 2em;
    list-style-type: circle;
  }
}

.map-container {
  position: relative;
  height: 100%;
  width: 100%;
  padding-top: 50%; /* Aspect ratio of 2:1 */
}

.map {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.map-column, .table-column {
  flex: 1 0 50%; /* flex-grow flex-shrink flex-basis */
  min-height: 300px; /* Minimum height to maintain usability */
}

/* FilteredTable.css */

.filtered-table-container {
  width: 100%;
  padding: 15px; // Adds padding around the table
  overflow-x: auto; /* Ensure horizontal overflow is handled */
}

.scrollable-tbody {
  display: block;
  max-height: 400px; /* Adjust as needed */
  overflow-y: auto; /* Add vertical scroll */
}

.bw-table thead, .bw-table tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.bw-table thead {
  width: calc(100% - 1em); /* Account for scrollbar width */
}

.bw-table tbody {
  display: block;
  width: 100%;
}

.options-column {
  width: 150px;
  white-space: nowrap;
}

.tag-table-item span {
  word-break: break-all;
}